import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"

class Contact extends React.Component {

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const specialClass = 'simple-page'

    return (
      <Layout location={this.props.location} title={siteTitle} specialClass={specialClass}>
        <SEO
          title='Contact'
          description='How to contact us'
        />
        <section className="hidden"></section>
        <section>
          <div className="page-section container">
            <div className="page-content">
              <h1>Contact Information</h1>
              <p> You can contact us at contact[at]invizi.co. </p>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
